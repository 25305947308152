define('garage/mbr-vehicles-parser/service', ['exports', 'papaparse', 'garage/config/environment', 'lodash'], function (exports, _papaparse, _environment, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        init: function init() {
            this._super.apply(this, arguments);
        },

        modalMessage: Ember.inject.service(),

        mappings: _environment.default.APP.import.capacityMappings,
        encoding: _environment.default.APP.import.encoding,

        parseFile: function parseFile(file, encoding) {
            return new Promise(function (res, rej) {
                _papaparse.default.parse(file, {
                    encoding: encoding,
                    header: true,
                    complete: res,
                    error: rej
                });
            });
        },


        // create capacity string to be used as a "unique index" for each capacity
        // configuration. this is used for filtering out possible duplicates since
        // we are not bringing over all mobirouter capacities.
        capstring: function capstring(caps) {
            return this.mappings.meaningfulMbrCapacities.map(function (capname) {
                return caps[capname];
            }).join('-');
        },
        emptycapstring: function emptycapstring() {
            return new Array(this.mappings.meaningfulMbrCapacities.length).fill(0).join('-');
        },
        squashCapacities: function squashCapacities(capgroups) {
            var _this = this;

            var emptycap = this.emptycapstring();
            Object.values(capgroups).forEach(function (caps) {
                var cleancaps = _lodash.default.uniqBy(caps, _this.capstring.bind(_this));
                cleancaps = cleancaps.filter(function (c) {
                    return _this.capstring(c) !== emptycap;
                });
                if (cleancaps.length !== caps.length) {
                    console.log('WARNING! ' + caps[0].KULKUNEUVO + ' has "zero capacity row"');
                }
                if (caps[0].KULKUNEUVO) {
                    capgroups[caps[0].KULKUNEUVO] = cleancaps;
                } else {
                    // delete empty lines
                    delete capgroups[caps[0].KULKUNEUVO];
                }
            });
        },


        // group capacities by each vehicle
        groupCapacities: function groupCapacities(capacities) {
            var capgroups = _lodash.default.groupBy(capacities, 'KULKUNEUVO');
            this.squashCapacities(capgroups);
            return capgroups;
        },
        capacityConfigToVinka: function capacityConfigToVinka(cap) {
            var _this2 = this;

            return this.mappings.meaningfulMbrCapacities.map(function (mbrcapname) {
                if (parseInt(cap[mbrcapname]) === 0) {
                    return null;
                }

                var ret = {
                    unitId: parseInt(_this2.mappings.capacityMbrMap[mbrcapname]),
                    count: parseInt(cap[mbrcapname])
                };

                (true && !(ret.unitId > 0) && Ember.assert('got capa unitId ' + ret.unitId + ' for vehicle ' + cap.KULKUNEUVO, ret.unitId > 0));
                (true && !(ret.count > 0) && Ember.assert('got capa count ' + ret.count + ' for vehicle ' + cap.KULKUNEUVO, ret.count > 0));


                return ret;
            }).filter(function (cap) {
                return cap !== null;
            });
        },
        attributeToVinka: function attributeToVinka(attribute) {
            var id = this.mappings.attributeMap[attribute];
            return id > 0 ? { id: id } : null;
        },
        vehiclesToVinka: function vehiclesToVinka(vehicles, capacities, prefixes, externalIds) {
            var _this3 = this;

            var capacitiesWithVehcile = {};
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = Object.keys(vehicles)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var vNbr = _step.value;

                    if (capacities[vNbr] && capacities[vNbr].length) {
                        capacitiesWithVehcile[vNbr] = capacities[vNbr];
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            var vehicleDatas = Object.values(capacitiesWithVehcile).map(function (caps) {
                var number = caps[0].KULKUNEUVO;
                var vehicle = vehicles[number];
                if (!vehicle) {
                    console.log('capacities contain vehicle data which is not found', number);
                    return null;
                }
                (true && !(vehicle) && Ember.assert('vehicle ' + number + ' not found from vehicle file', vehicle));

                var name = (vehicle.NIMI || '').trim();
                var capacityConfigs = caps.map(function (cap) {
                    return _this3.capacityConfigToVinka(cap);
                });
                var attributes = (vehicle.VARUSTEET || '').trim().split('').map(function (attr) {
                    return _this3.attributeToVinka(attr);
                }).filter(function (a) {
                    return a !== null;
                });

                var priceFactor = parseFloat((vehicle.HINTAMUUTOS || '').trim().replace(',', '.'));
                priceFactor = isNaN(priceFactor) ? 1 : priceFactor;

                var emissionFactor = _this3.parseEmissionFactor(vehicle.HIDASAJO_KERROIN);

                var phoneNumber = (vehicle.PUHELINNUMERO || '').trim();
                phoneNumber = phoneNumber.length > 0 && phoneNumber !== '0' ? phoneNumber : null; // empty phone is not accepted

                var dispatchPriority = parseInt((vehicle.PRIORITEETTI || '').trim(), 10);
                dispatchPriority = isNaN(dispatchPriority) ? 100 : dispatchPriority;

                var dispatchArea = (vehicle.ALUEET || '').trim();
                dispatchArea = dispatchArea.length > 0 ? dispatchArea : null;

                var useShift = (vehicle.AJOVUORO || '').trim().length > 0 ? true : false;

                var _ref = [parseFloat(vehicle.GARAGE_LAT), parseFloat(vehicle.GARAGE_LNG), vehicle.GARAGE_ALIAS || ''],
                    lat = _ref[0],
                    lng = _ref[1],
                    alias = _ref[2];

                var garage = isNaN(lat) || isNaN(lng) ? null : { lat: lat, lng: lng, alias: alias };

                // let operator;

                var externalId = (vehicle.ULKOINEN_TUNNUS || '').trim() || null;
                var paymentTerminalId = (vehicle.MAKSUPAATETUNNUS || '').trim() || null;

                (true && !(number) && Ember.assert('no vehicle number found', number));
                (true && !(name) && Ember.assert('no name found for vehicle ' + number, name));
                (true && !(capacityConfigs.length > 0) && Ember.assert('got 0 capacities for vehicle ' + number, capacityConfigs.length > 0));


                if (prefixes) {
                    var numbersz = String(number);
                    var pref = prefixes.find(function (p) {
                        return numbersz.startsWith(p.prefix);
                    });
                    (true && !(pref) && Ember.assert('prefix not found for ' + numbersz, pref));

                    number = parseInt(numbersz.substring(pref.prefix.length));
                    // operator = pref.operator;
                }

                if (externalIds) {}
                // externalId = name.split(' ')[1];


                // return {number, externalId, name, operator, active: false, attributes, capacityConfigs};
                return { number: number, name: name, priceFactor: priceFactor, emissionFactor: emissionFactor, phoneNumber: phoneNumber, dispatchPriority: dispatchPriority, dispatchArea: dispatchArea, useShift: useShift,
                    active: false, attributes: attributes, capacityConfigs: capacityConfigs, garage: garage, externalId: externalId, paymentTerminalId: paymentTerminalId };
            });

            var finals = vehicleDatas.filter(function (i) {
                return i !== null;
            });

            return finals;
        },
        parse: function parse(vehfile, capfile) {
            var _this4 = this;

            var capacities = void 0;
            var vehicles = void 0;

            return this.parseFile(capfile, this.encoding).then(function (parsed) {
                return _this4.groupCapacities(parsed.data);
            }).then(function (capas) {
                capacities = capas;
                return _this4.parseFile(vehfile, _this4.encoding);
            }).then(function (parsed) {
                vehicles = _lodash.default.keyBy(parsed.data, 'KULKUNEUVO');
            }).then(function () {
                return _this4.vehiclesToVinka(vehicles, capacities, undefined, false);
            }).catch(function (e) {
                console.log(e);
                _this4.get('modalMessage').showDialog('Import Interrupted', e.message);
            });
        },


        /**
         * Answer from customer: HIDASAJO_KERROIN field is good to be used for this. As an exception value 0 should equal to
         * value 100 in the import values. So let’s convert the values from tsv:
            100 -> 1
            98 -> 0,98
            106 -> 1,06
            0 -> 1
         * @param emissionFactor
         * @returns {null|number}
         */
        parseEmissionFactor: function parseEmissionFactor(emissionFactor) {
            emissionFactor = parseInt(('' + emissionFactor).trim(), 10);

            // if the emission factor column is missing OR the value is missing OR is invalid - return null
            if (isNaN(emissionFactor)) {
                return null;
            }

            return emissionFactor === 0 ? 1 : emissionFactor / 100;
        }
    });
});