define('garage/vehicle-export/service', ['exports', 'papaparse', 'garage/config/environment', 'lodash'], function (exports, _papaparse, _environment, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    function attributesToCSV(attrs) {
        var ids = attrs.map(function (a) {
            return a.id;
        });
        return ids.join(',');
    }

    function mapCapacities(caps) {
        return caps.reduce(function (all, c) {
            all[c.unitId] = c.count;
            return all;
        }, {});
    }

    var fields = ['id', 'name', 'attributes', 'phoneNumber', 'licenceNumber', 'emissionFactor', 'co2', 'priceFactor', 'dispatchArea', 'dispatchPriority', 'externalId', 'paymentTerminalId', 'contract', 'useShift', 'labels', 'brandModel', 'additionalInfo', 'inactive'];
    var capFields = ['id'].concat(_toConsumableArray(Array.from(Array(6), function (_, i) {
        return i + 1;
    }).map(function (x) {
        return 'cap_' + x;
    })));

    exports.default = Ember.Service.extend({
        init: function init() {
            this._super.apply(this, arguments);
        },

        modalMessage: Ember.inject.service(),

        mappings: _environment.default.APP.import.capacityMappings,
        encoding: _environment.default.APP.import.encoding,
        vehiclesToCSVObjects: function vehiclesToCSVObjects(vehicles) {
            return vehicles.map(function (v) {
                // note: this order must match order in fields array
                return [v.get('id'), v.get('name'), attributesToCSV(v.get('attributes')), v.get('phoneNumber'), v.get('licenceNumber'), v.get('emissionFactor'), v.get('co2'), v.get('priceFactor'), v.get('dispatchArea'), v.get('dispatchPriority'), v.get('externalId'), v.get('paymentTerminalId'), v.get('contract'), v.get('useShift') ? 'x' : '', v.get('labels'), v.get('brandModel'), v.get('additionalInfo'), v.get('inactive') ? 'x' : ''];
            }
            // garage fields missing here
            );
        },
        vehicleCapsToCSVObjects: function vehicleCapsToCSVObjects(vehicles) {
            var data = vehicles.map(function (v) {
                return v.get('capacityConfigs').map(function (c) {
                    var caps = mapCapacities(c);
                    return [v.id].concat(_toConsumableArray(Array.from(Array(6), function (_, i) {
                        return i + 1;
                    }).map(function (x) {
                        return caps[x] || 0;
                    })));
                }
                // above are caps from 1..6, need more 1000, 1001?
                );
            });
            return _lodash.default.flatten(data);
        },
        export: function _export(vehicles, fileTitle, encoding) {
            var csvData = this.vehiclesToCSVObjects(vehicles);

            var csv = _papaparse.default.unparse({
                fields: fields,
                data: csvData
            }, {
                delimiter: '\t'
            });

            // download
            var vehiclesFileName = (fileTitle || 'vehicles') + '.tsv';
            this.downloadFile(csv, vehiclesFileName, encoding);

            var csvCapData = this.vehicleCapsToCSVObjects(vehicles);
            var csvCaps = _papaparse.default.unparse({
                fields: capFields,
                data: csvCapData
            }, {
                delimiter: '\t'
            });

            // download
            var capsFileName = (fileTitle || 'capacities') + '.tsv';
            this.downloadFile(csvCaps, capsFileName, encoding);
        },
        downloadFile: function downloadFile(csv, exportedFileName, encoding) {
            var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            if (navigator.msSaveBlob) {
                // IE 10+
                navigator.msSaveBlob(blob, exportedFileName);
            } else {
                var link = document.createElement('a');
                if (link.download !== undefined) {
                    // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    link.setAttribute('download', exportedFileName);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        }
    });
});