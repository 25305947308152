define('garage/components/custom-translate/component', ['exports', 'garage/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        i18n: Ember.inject.service(),

        translation: Ember.computed('key', 'i18n.locale', function () {
            var customer = _environment.default.i18n.customerLocale;
            var text = '';
            var loc = this.get('key');
            if (customer && customer !== 'default') {
                text = this.get('i18n').t('customerLocale.' + customer + '.' + loc, {
                    default: loc
                });
            } else {
                text = this.get('i18n').t(loc);
            }

            return text;
        })
    });
});