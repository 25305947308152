define('garage/vehicle/model', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend(Ember.Copyable, {
        name: _emberData.default.attr('string'),
        operator: _emberData.default.attr('number'),
        number: _emberData.default.attr('number'),
        externalId: _emberData.default.attr('string'),
        paymentTerminalId: _emberData.default.attr('string'),
        licenceNumber: _emberData.default.attr('string'),
        phoneNumber: _emberData.default.attr('string'),
        location: _emberData.default.attr(),
        attributes: _emberData.default.attr(),
        capacityConfigs: _emberData.default.attr(),
        dispatchPriority: _emberData.default.attr('number'),
        dispatchArea: _emberData.default.attr(),
        priceFactor: _emberData.default.attr('number'),
        emissionFactor: _emberData.default.attr('number'),
        co2: _emberData.default.attr('number'),
        serviceFactor: _emberData.default.attr('number'),
        useShift: _emberData.default.attr('boolean'),
        garage: _emberData.default.attr(),
        contract: _emberData.default.attr(),
        additionalInfo: _emberData.default.attr('string'),
        brandModel: _emberData.default.attr('string'),
        labels: _emberData.default.attr(),
        inactive: _emberData.default.attr('boolean'),

        isUnsaved: Ember.computed('id', function () {
            return this.get('id') && this.get('id').startsWith('new');
        }),

        copy: function copy() {
            var loc = this.get('store').createRecord('location', this.get('location'));
            return this.get('store').createRecord('vehicle', {
                name: 'Copy of ' + this.get('name'),
                number: this.get('number'),
                paymentTerminalId: this.get('paymentTerminalId'),
                licenceNumber: this.get('licenceNumber'),
                phoneNumber: this.get('phoneNumber'),
                location: loc,

                attributes: JSON.parse(JSON.stringify(this.get('attributes'))),
                capacityConfigs: JSON.parse(JSON.stringify(this.get('capacityConfigs'))),
                dispatchPriority: this.get('dispatchPriority'),
                dispatchArea: this.get('dispatchArea'),
                priceFactor: this.get('priceFactor'),
                emissionFactor: this.get('emissionFactor'),
                co2: this.get('co2'),
                serviceFactor: this.get('serviceFactor'),
                useShift: this.get('useShift'),
                contract: this.get('contract') ? JSON.parse(JSON.stringify(this.get('contract'))) : this.get('contract'),
                additionalInfo: this.get('additionalInfo'),
                brandModel: this.get('brandModel'),
                labels: this.get('labels') ? JSON.parse(JSON.stringify(this.get('labels'))) : this.get('labels'),
                inactive: this.get('inactive')
            });
        },


        // ember-api-actions start here

        genReport: (0, _emberApiActions.memberAction)({ path: 'report', type: 'get' }),

        genQRCode: (0, _emberApiActions.memberAction)({ path: 'qr-code', type: 'get' })
    });
});