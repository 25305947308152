define('garage/initializers/application', ['exports', 'garage/config/environment', 'garage/helpers/permission-utils'], function (exports, _environment, _permissionUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    function applyConfig(env, config) {
        function resolve(parts, root) {
            return parts.reduce(function (p, c) {
                return p[c];
            }, root);
        }

        Object.keys(config).forEach(function (c) {
            var parts = c.split('.');
            parts.shift(); // remove the root (ENV)
            var key = parts.pop();
            var target = resolve(parts, env);
            target[key] = config[c];
        });

        env.contentSecurityPolicy['connect-src'] += ' ' + env.APP.api.host + ':*';
    }

    function initialize() /* application */{
        var _getSiteName = (0, _permissionUtils.getSiteName)(),
            _getSiteName2 = _slicedToArray(_getSiteName, 2),
            site = _getSiteName2[0],
            rootURL = _getSiteName2[1];

        console.log('** site: ' + site + ', rootURL: ' + rootURL);
        if (site) {
            console.debug('dynamically reading config for ' + site);
            var conf = _environment.default.APP.envs[site] || {};
            conf['ENV.rootURL'] = rootURL;
            if (!conf['ENV.APP.api.host']) {
                conf['ENV.APP.api.host'] = rootURL === '/' ? 'https://garage-api.' + site + '.vinka.cloud' : 'https://' + site + '.vinka.cloud/garage-api';
            }
            applyConfig(_environment.default, conf);
        }
    }

    exports.default = {
        initialize: initialize
    };
});