define('garage/driver/driver/index/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Route.extend({
        viewData: Ember.Object.create({
            filterText: '',
            operators: [],
            filteredOperators: [],
            selectedOperator: null,
            driver: null,
            driverStatus: ['active', 'inactive'],
            trainings: [], // options formatted for select box
            driverTrainings: [] // driver selections
        }),

        model: function model() {
            var _this = this;

            this.set('viewData.driver', this.modelFor('driver.driver').driver);

            this.get('store').findAll('operator').then(function (result) {
                var driver = _this.get('viewData.driver');

                _this.set('viewData.operators', result);

                // for a new driver preselect the operator if ony one is available
                if (driver.get('id').startsWith('new') && !driver.get('operator') && _this.get('viewData.operators').get('length') === 1) {
                    var operator = _this.get('viewData.operators').get('firstObject');

                    driver.set('operator', +operator.get('id'));
                    driver.set('operatorName', operator.get('name'));
                }

                _this.set('viewData.filteredOperators', _this.get('viewData.operators').filter(Boolean));
                _this.set('viewData.selectedOperator', result.find(function (i) {
                    return driver.get('operator') === +i.get('id');
                }));

                _this.get('store').findRecord('optionSet', 'driver-trainings').then(function (optionSet) {
                    // map optionsSet to select options
                    var trainings = optionSet.get('options').map(function (c) {
                        return { key: c.key, name: c.value };
                    });
                    _this.set('viewData.trainings', trainings);
                });
            });

            return this.get('viewData');
        },


        actions: {
            selectOperator: function selectOperator(dropdown, operator) {
                dropdown.actions.close();

                this.set('viewData.selectedOperator', operator);

                this.set('viewData.driver.operator', operator ? +operator.get('id') : null);
            },
            statusSelect: function statusSelect(select) {
                this.set('viewData.driver.status', select.target.value);
            },
            onTrainingSelect: function onTrainingSelect(value) {
                if (!this.get('viewData.driver.training')) {
                    this.get('viewData.driver').set('training', []);
                }

                if (!this.get('viewData.driver.training').find(function (i) {
                    return i === value;
                })) {
                    this.get('viewData.driver').set('training', [].concat(_toConsumableArray(this.get('viewData.driver.training')), [value]));
                }
            },
            onTrainingRemove: function onTrainingRemove(value) {
                var removeValue = value.key || value;
                this.get('viewData.driver').set('training', this.get('viewData.driver.training').filter(function (i) {
                    return i !== removeValue;
                }));
            },
            onTrainingClear: function onTrainingClear() {
                this.get('viewData.driver').set('training', []);
            }
        },

        filterObserver: Ember.observer('viewData.filterText', function () {
            var _this2 = this;

            var filter = this.get('viewData.filterText').toLowerCase();

            this.set('viewData.filteredOperators', this.get('viewData.operators').filter(function (i) {
                return _this2._filterOperator(i, filter);
            }));
        }),

        driverTrainingObserver: Ember.observer('viewData.driver.training', 'viewData.trainings', function () {
            var choices = this.get('viewData.trainings') || [];
            var driverTrainings = this.get('viewData.driver.training') || [];
            var selectedOnes = driverTrainings.map(function (vc) {
                return choices.find(function (c) {
                    return c.key === vc;
                }) || vc;
            });

            this.set('viewData.driverTrainings', selectedOnes);
        }),

        _filterOperator: function _filterOperator(operator, filter) {
            return ('' + operator.get('companyName')).toLowerCase().includes(filter) || ('' + operator.get('companyAddress')).toLowerCase().includes(filter) || ('' + operator.get('userEmail')).toLowerCase().includes(filter) || ('' + operator.get('contactName')).toLowerCase().includes(filter) || ('' + operator.get('contactPhone')).toLowerCase().includes(filter) || ('' + operator.get('contactEmail')).toLowerCase().includes(filter) || ('' + operator.get('businessId')).toLowerCase().includes(filter);
        }
    });
});