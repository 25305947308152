define('garage/field-config/route', ['exports', 'garage/mixins/restricted-route-mixin', 'garage/helpers/permission-utils', 'garage/helpers/api-error-handler'], function (exports, _restrictedRouteMixin, _permissionUtils, _apiErrorHandler) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_restrictedRouteMixin.default, {
        i18n: Ember.inject.service(),

        modalMessage: Ember.inject.service(),

        // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
        restrictions: [_permissionUtils.roleNames.admin, _permissionUtils.roleNames.super],

        viewData: Ember.Object.create({
            optionSets: []
        }),

        model: function model() {
            var _this = this;

            this.get('store').findAll('optionSet').then(function (result) {
                _this.set('viewData.optionSets', result);
            });
            return this.get('viewData');
        },


        actions: {
            addOption: function addOption(optionSet) {
                var options = optionSet.get('options');
                options.addObject({ key: '', value: '' });
            },
            removeOption: function removeOption(optionSet, optionIndex) {
                var options = optionSet.get('options');
                options.removeAt(optionIndex);
            },
            save: function save(optionSetName) {
                var _this2 = this;

                var onConfirm = function onConfirm() {
                    var optionSets = _this2.get('viewData.optionSets');
                    var optionSet = optionSets.findBy('name', optionSetName);

                    _this2.get('store').findRecord('optionSet', optionSetName).then(function (_optionSet) {
                        _optionSet.set('optionSet', optionSet);
                        _optionSet.save().catch(function (err) {
                            var title = _this2.get('i18n').t('message.title.failure');
                            var text = (0, _apiErrorHandler.formatApiError)(err, _this2.get('i18n'));
                            _this2.get('modalMessage').showDialog(title, text);
                        });
                    });
                };

                var isValid = function isValid() {
                    var optionSets = _this2.get('viewData.optionSets');
                    var optionSet = optionSets.findBy('name', optionSetName);
                    return !(optionSet.get('options') || []).some(function (o) {
                        return !o.key || !o.value;
                    });
                };

                var key = 'table.fieldConfig.title.' + optionSetName;
                var text = this.get('i18n').exists(key) ? this.get('i18n').t(key) : optionSetName;

                if (!isValid()) {
                    this.get('modalMessage').showDialog(this.get('i18n').t('dialog.fieldConfig.fieldErrorTitle'), this.get('i18n').t('dialog.fieldConfig.fieldErrorText', {
                        fieldName: text
                    }));
                } else {
                    this.get('modalMessage').confirm(this.get('i18n').t('dialog.fieldConfig.title', {
                        fieldName: text
                    }), this.get('i18n').t('dialog.fieldConfig.text'), onConfirm.bind(this));
                }
            }
        }
    });
});