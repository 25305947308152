define('garage/vehicle/vehicle/capacity/route', ['exports', 'garage/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var findCapacityLocalization = function findCapacityLocalization(locale, capacity) {
        if (capacity && capacity.get('i18n') && locale) {
            return capacity.get('i18n')[locale];
        }
        return undefined;
    };

    exports.default = Ember.Route.extend({
        i18n: Ember.inject.service(),

        viewData: Ember.Object.create({
            vehicletypes: [],
            capacityUnits: [],
            vehicleAttributes: [],
            capacityConfigs: [],
            vehicle: null,
            type: null,
            useCustomCapacity: true
        }),

        /**
         * Set the current vehicle type in to the `viewModel`.
         */
        refreshType: function refreshType() {
            var types = this.get('viewData.vehicletypes');
            var n = types.get('length');
            for (var i = 0; i < n; i++) {
                if (parseInt(types.objectAt(i).id) === parseInt(this.get('viewData.vehicle.typeId'))) {
                    this.set('viewData.type', types.objectAt(i));
                    break;
                }
            }
        },


        /**
         * Copy capacities data to the vehicle specific array.
         */
        saveCapacities: function saveCapacities() {
            var configs = [];
            // Copy current setup to the vehicles.
            this.get('viewData.capacityConfigs').forEach(function (unit) {
                var count = parseInt(unit.get('value'));
                if (count) {
                    configs.push({
                        unitId: parseInt(unit.get('id')),
                        count: count
                    });
                }
            });
            this.set('viewData.vehicle.capacityConfigs', configs);
        },


        /**
         * Copy vehicle attributes list to the vehicle.
         */
        saveAttributes: function saveAttributes() {
            var attrs = [];
            this.get('viewData.vehicleAttributes').forEach(function (attr) {
                if (attr.get('value')) {
                    attrs.push({ id: attr.get('id'), name: attr.get('name') });
                }
            });
            this.set('viewData.vehicle.attributes', attrs);
        },


        /**
         * Copy capacities from vehicle or from type to capacities array.
         */
        loadCapacities: function loadCapacities() {
            var _this = this;

            var counts = {};
            var capacityConfigs = [];
            var locale = this.get('i18n').locale;
            var existingCaps = this.get('viewData.vehicle.capacityConfigs');
            if (existingCaps && existingCaps.length) {
                // Init from vehicles own capacities.
                existingCaps.forEach(function (capacity) {
                    counts[capacity.unitId] = (counts[capacity.unitId] || 0) + capacity.count;
                });
            }

            // Mark counts to the capacity list.
            this.get('viewData.capacityUnits').forEach(function (unit) {
                var attributeLoc = findCapacityLocalization(locale, unit);
                var translationKey = 'form.capacities.' + unit.get('name');
                var translationExists = _this.get('i18n').exists(translationKey);
                var id = parseInt(unit.get('id'));
                var obj = Ember.Object.create({
                    id: id,
                    value: counts[id] || 0,
                    name: unit.get('name'),
                    translation: attributeLoc ? attributeLoc : translationExists ? _this.get('i18n').t(translationKey) : '' + unit.get('name')
                });
                capacityConfigs.push(obj);
            });

            // sort for UI to show in order
            capacityConfigs.sort(function (a, b) {
                return a.id - b.id;
            });

            this.set('viewData.capacityConfigs', capacityConfigs);
        },


        /**
         * Copy attributes from vehicle or from type to capacities array.
         */
        loadAttributes: function loadAttributes() {
            var selected = {};

            if (this.get('viewData.vehicle.attributes') && this.get('viewData.vehicle.attributes').length) {
                // Use vehicles own attributes.
                this.get('viewData.vehicle.attributes').forEach(function (attr) {
                    selected[attr.id] = true;
                });
                this.set('viewData.useCustomCapacity', true);
            } else {
                // Use vehicle type's attributes.
                this.get('viewData.type.attributes').forEach(function (attr) {
                    selected[attr.id] = true;
                });
            }
            // Mark selected attributes to the vehicle attribute list.
            this.get('viewData.vehicleAttributes').forEach(function (attr) {
                attr.set('value', !!selected[attr.id]);
            });
        },
        model: function model() {
            var _this2 = this;

            return this.get('store').findAll('capacity').then(function (availableCapacities) {
                _this2.set('viewData.capacityUnits', availableCapacities.filter(function (unit) {
                    return !(_environment.default.APP.capacity.ignore || []).includes(+unit.id);
                }));
                _this2.set('viewData.vehicle', _this2.modelFor('vehicle.vehicle').vehicle);
                _this2.loadCapacities();
                return _this2.get('viewData');
            });
        },


        actions: {
            selectType: function selectType(option) {
                if (option && parseInt(this.get('viewData.vehicle.typeId')) !== parseInt(option.get('id'))) {
                    this.set('viewData.vehicle.typeId', option.get('id'));
                    this.refreshType();
                    this.loadCapacities();
                    this.loadAttributes();
                }
            },
            capacityChanged: function capacityChanged() {
                this.saveCapacities();
            },
            toggleAttribute: function toggleAttribute() {
                this.saveAttributes();
            },
            toggleCustomActivator: function toggleCustomActivator(state) {
                if (!state) {
                    // Reset vehicle specific values if custom is turned off.
                    this.set('viewData.vehicle.capacityConfigs', []);
                    this.set('viewData.vehicle.attributes', []);
                    this.loadCapacities();
                    this.loadAttributes();
                }
            },
            capacitiesChanged: function capacitiesChanged(oldCapsRow, newCapsRow) {
                // need to re-create capacity array and set new capacity item so that rollback works
                var caps = this.get('viewData.vehicle.capacityConfigs') || [];
                var items = caps.map(function (i) {
                    if (i === oldCapsRow) {
                        return newCapsRow;
                    }
                    return i;
                });
                this.set('viewData.vehicle.capacityConfigs', items);
            },
            removeCapacityRow: function removeCapacityRow(capacityRow) {
                // need to re-create capacity array and remove capacity item so that rollback works
                var items = this.get('viewData.vehicle.capacityConfigs').filter(function (i) {
                    return i !== capacityRow;
                });
                this.set('viewData.vehicle.capacityConfigs', items);
            },
            addCapacityRow: function addCapacityRow(capacityRow) {
                // need to re-create capacity array and add new capacity item so that rollback works
                var caps = this.get('viewData.vehicle.capacityConfigs') || [];
                var items = caps.map(function (i) {
                    return i;
                });
                items.push(capacityRow);
                this.set('viewData.vehicle.capacityConfigs', items);
            }
        }
    });
});