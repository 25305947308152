define('garage/helpers/translate-option-key', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.translateKey = translateKey;


  /**
   * Helper for translating e.g. contracts and labels from option key choices.
   *
   * @param {*} params
   *   param[0] - key as string text
   *   param[1] - translations in form: [{key: 'abc', name: 'Abc'}, {key: 'efg', name: 'Efg'}]
   *   param[2] - boolean for including key in addition to returned value. true - include, false - not, default is false
   * @returns name as text
   */
  function translateKey(params) {
    var key = params[0];
    var translations = params[1];
    var includeKey = !!params[2];
    var found = (translations || []).find(function (c) {
      return c.key === key;
    });

    return found ? '' + found.name + (includeKey ? ' (' + key + ')' : '') : key;
  }

  exports.default = Ember.Helper.helper(translateKey);
});